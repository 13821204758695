/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import HeadlineAndText from 'Accessories/HeadlineAndText/HeadlineAndText';
import BookingForm from 'Layout/Forms/BookingForm/BookingForm';
import classNames from "classnames";

import './BookingSection.scss';

const BookingSection = (props) => {

  const { headline, subheadline, bookingTypes, hasIcons } = props
  const [ packageType, setPackageType ] = useState(0)
  const [ packageName, setPackageName ] = useState("")

  const updatePackage = (index, title) => {
    setPackageType(index + 1)
    setPackageName(title)
  }

  return (
    <div className="section booking-section container pb-8 pt-6">
      <div className="row text-align-center">
        <div className={classNames(hasIcons && "mb-lg-2", "col-12 col-md-10 col-lg-8 mx-auto")}>
          <HeadlineAndText headline={headline} text={subheadline} isSubHeadline hasIcons={hasIcons} />
        </div>
      </div>
      <div className={`row justify-content-between bg-white pb-1 ${packageType === 1 ? 'active-package-1' : ''} ${packageType === 2 ? 'active-package-2' : ''} ${packageType === 3 ? 'active-package-3' : ''}`}>
        {bookingTypes.map((bookingType, index) => (
          <div
            className="booking-type-wrapper mb-4 mb-lg-0"
            key={bookingType.title}
            role="button"
            onClick={() => updatePackage(index, bookingType.title)}
          >
            <a href="#buchen">
              <div className="booking-type rounded-shadow px-2 pt-1 pb-5 pb-lg-2">
                {
                  bookingType.title && (
                    <div className="booking-type-title text-align-center">
                      <h3 className="text-l">{bookingType.title}</h3>
                    </div>
                  )
                }
                <div className="booking-details-wrapper d-flex flex-column flex-grow-1 justify-content-between">
                  <div className="booking-type-info mt-2">
                    {
                      bookingType.info_items.length > 0 && bookingType.info_items.map(item => (
                        <span key={item.info_item} className="d-block">{item.info_item}</span>
                      ))
                    }
                  </div>
                  <div className="booking-type-bonus mt-2">
                    <span>{bookingType.info_bonus}</span>
                  </div>
                </div>
                <div
                  className="booking-type-button btn btn-secondary bg-white rounded-shadow px-1 d-flex justify-content-between"
                  role="button"
                  onClick={() => updatePackage(index, bookingType.title)}
                >
                  <span className="btn btn-secondary">Jetzt Buchen</span>
                  <span className="btn btn-secondary">{`${bookingType.info_price} €`}</span>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      <BookingForm packageType={packageType} packageName={packageName} containerClasses={classNames(packageType !== "" && "is-open")} />
    </div>
  )
}

export default BookingSection