/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby'
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Flatpickr from 'react-flatpickr';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/themes/material_green.css';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as SvgIconSmiley } from "static/assets/brand/icons/Juto_smiley.svg";

import Button from 'Accessories/Button/Button';
import HeadlineAndText from 'Accessories/HeadlineAndText/HeadlineAndText';
import cn from 'classnames';

import "./BookingForm.scss"

const BookingForm = (props) => {
  const { headline, containerClasses, formClasses, packageType, packageName } = props;

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [prefix, setPrefix] = useState("");
  const [extraBlanket, setExtraBlanket] = useState("");
  const [extraAppleJuice, setExtraAppleJuice] = useState(false);
  const [extraInvitation, setExtraInvitation] = useState(false);
  const [extraFruits, setExtraFruits] = useState(false);
  const [extraSweets, setExtraSweets] = useState(false);
  const [extraCake, setExtraCake] = useState("");
  const [adults, setAdults] = useState("");
  const [children, setChildren] = useState("");
  const [firstNameBirthdayChild, setFirstNameBirthdayChild] = useState("");
  const [date, setDate] = useState("");
  const [dateFormatted, setDateFormatted] = useState("");
  const [time, setTime] = useState("10–13:30 Uhr");
  const [birthdayDate, setBirthdayDate] = useState("");
  const [birthdayDateFormatted, setBirthdayDateFormatted] = useState("");
  const [newsletterSubscription, setNewsletterSubscription] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [submitBookingSuccess, setSubmitBookingSuccess] = useState();
  const [submitBookingError, setSubmitBookingError] = useState();
  const [submitConfirmationSuccess, setSubmitConfirmationSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const bookingMessageRef = useRef()

  const scrollToRef = (ref) => {
    if (window) {
      window.scrollTo(0, ref.current.offsetTop - 140)
    }
  }

  let currentMonth;

  const matchMonth = (m) => {
    switch (m) {
      case 'Jan':
          currentMonth = 1;
        break;
      case 'Feb':
          currentMonth = 2;
        break;
      case 'Mar':
          currentMonth = 3;
        break;
      case 'Apr':
          currentMonth = 4;
        break;
      case 'May':
          currentMonth = 5;
        break;
      case 'Jun':
          currentMonth = 6;
        break;
      case 'Jul':
          currentMonth = 7;
        break;
      case 'Aug':
          currentMonth = 8;
        break;
      case 'Sep':
          currentMonth = 9;
        break;
      case 'Oct':
          currentMonth = 10;
        break;
      case 'Nov':
          currentMonth = 11;
        break;
      case 'Dec':
          currentMonth = 12;
        break;
      default:
        currentMonth = "";
    }
  }

  const updatePartyDateFormat = () => {
    if(date) {
      const pDateFormatted = date[0].toString().split(' ');
      const pDateDay = pDateFormatted[2];
      const pDateMonth = pDateFormatted[1];
      const pDateYear = pDateFormatted[3];
      matchMonth(pDateMonth);
      setDateFormatted(`${pDateDay}.${currentMonth}.${pDateYear}`);
    }
  }

  const updateBirthdayDateFormat = () => {
    if(birthdayDate) {
      const bDateFormatted = birthdayDate[0].toString().split(' ');
      const bDateDay = bDateFormatted[2];
      const bDateMonth = bDateFormatted[1];
      const bDateYear = bDateFormatted[3];

      matchMonth(bDateMonth);

      setBirthdayDateFormatted(`${bDateDay}.${currentMonth}.${bDateYear}`);
    }
  }

  const submitConfirmation = async () => {
    try {
      const confirmationData = await fetch(`/.netlify/functions/confirmation`, {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
        headers: {'Content-Type': 'application/json'}
      })
      setSubmitConfirmationSuccess(true)
      return confirmationData
    }
    catch(err) {
      console.log('Error: ', err)
      setSubmitConfirmationSuccess(false)
      return false
    }
  }

  const submitErrorMail = async () => {
    try {
      const confirmationData = await fetch(`/.netlify/functions/booking-error`, {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
        headers: {'Content-Type': 'application/json'}
      })
      return confirmationData
    }
    catch(err) {
      console.log('Error: ', err)
      return false
    }
  }

  const submitToServer = async () => {
    try {
      const bookingsData = await fetch(`/.netlify/functions/booking`, {
        method: "POST",
        body: JSON.stringify({
          firstname,
          lastname,
          packageType,
          packageName,
          email,
          note,
          phone,
          prefix,
          street,
          zip,
          city,
          adults,
          children,
          firstNameBirthdayChild,
          newsletterSubscription,
          dateFormatted,
          time,
          birthdayDateFormatted,
          extraBlanket,
          extraAppleJuice,
          extraInvitation,
          extraFruits,
          extraSweets,
          extraCake,
        }),
        headers: {'Content-Type': 'application/json'}
      })
      console.log('Success: ', bookingsData)
      submitConfirmation()
      setSubmitBookingSuccess(true)
      scrollToRef(bookingMessageRef)
      return bookingsData
    }
    catch(err) {
      console.log('Error: ', err)
      submitErrorMail()
      setSubmitBookingError(true)
      scrollToRef(bookingMessageRef)
      return false
    }
  }

  useEffect(() => {
    if (dateFormatted || birthdayDateFormatted) {
      updateBirthdayDateFormat()
      updatePartyDateFormat()
    }
  },
  [dateFormatted, birthdayDateFormatted]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToServer()
  }

  const updateSteps = () => {
    setCurrentStep(currentStep+1);

    if(currentStep) {
      updateBirthdayDateFormat();
      updatePartyDateFormat();
    }
  }


  return (
    <div className={cn("booking-form-section section container pt-4 pb-8 bg-white", containerClasses)} id="buchen">
      {packageName && (
        <>
          {
            headline && (
              <div className="row text-align-center">
                <div className="col-12 col-md-10 col-lg-8 mx-auto">
                  <HeadlineAndText headline={headline} isSubHeadline />
                </div>
              </div>
            )
          }
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              {
                (!submitBookingSuccess && !submitConfirmationSuccess) && (
                  <form
                    className={cn("row rounded-shadow justify-content-between", formClasses)}
                    name="contact"
                    onSubmit={handleSubmit}
                  >
                    <FormLabel component="legend" className="form-section-title mb-1">
                      <div className="text-m">
                        Gewähltes Paket:&nbsp;
                        {packageName}
                      </div>
                      <div className="d-inline-block text-s mb-1">
                        Bei Fragen: 089 – 99 275 89 29
                      </div>
                    </FormLabel>
                    <div className={cn("flex-grow-1 mb-2", currentStep >= 1 ? "d-block" : "d-none")}>
                      <FormControl component="fieldset" className="booking-date form-control col-12 col-md-6 col-lg-3 mb-1 mb-md-0">
                        <FormLabel component="legend" className="date-legend">Datum der Party</FormLabel>
                        <Flatpickr
                          required
                          options={{
                            dateFormat: "d.m.Y",
                            minDate: "today",
                            "locale": {
                              "firstDayOfWeek": 1 // start week on Monday
                            },
                          }}
                          placeholder="Datum"
                          value={date}
                          onChange={newDate => { setDate(newDate) }}
                        />
                      </FormControl>
                      <FormControl component="fieldset" className="form-control col-12 col-md-6 col-lg-3">
                        <FormLabel component="legend">Uhrzeit</FormLabel>
                        <RadioGroup
                          required
                          aria-label="Uhrzeit"
                          name="time"
                          onChange={(e) => setTime(e.target.value)}
                        >
                          <FormControlLabel value="10–13:30 Uhr" control={<Radio checked={time ===  "10–13:30 Uhr"} />} label="10 bis 13:30 Uhr" />
                          <FormControlLabel value="15–18:30 Uhr" control={<Radio />} label="15 bis 18:30 Uhr" />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset" className="birthday-date-child form-control col-12 col-md-6 my-1 my-md-0">
                        <FormLabel component="legend" className="date-legend">Geburtstag des Kindes</FormLabel>
                        <Flatpickr
                          required
                          options={{
                            dateFormat: "d.m.Y",
                            "locale": {
                              "firstDayOfWeek": 1 // start week on Monday
                            }
                          }}
                          placeholder="Datum"
                          value={birthdayDate}
                          onChange={newDate => { setBirthdayDate(newDate) }}
                        />
                      </FormControl>
                      <TextField
                        required
                        id="outlined-child-name"
                        label="Vorname des Geburtstagskindes"
                        margin="normal"
                        type="text"
                        name="child-name"
                        variant="outlined"
                        className="material-input col-12 col-md-6 mt-md-1 mt-lg-0"
                        onChange={(e) => setFirstNameBirthdayChild(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-adults"
                        label="Erwachsene (10 € p.P.)"
                        margin="normal"
                        type="number"
                        step="1"
                        name="adults"
                        variant="outlined"
                        className="material-input col-md-6 col-lg-3 mt-md-1 mt-lg-0"
                        onChange={(e) => {setAdults(e.target.value) }}
                      />
                      <TextField
                        required
                        id="outlined-children"
                        label="Kinder (mind. 6)"
                        margin="normal"
                        type="number"
                        inputProps={{min: 6}}
                        min="6"
                        step="1"
                        name="children"
                        variant="outlined"
                        className="material-input col-md-6 col-lg-3 mt-md-1 mt-lg-0"
                        onChange={(e) => setChildren(e.target.value)}
                      />
                    </div>
                    <div className={cn("flex-grow-1 mb-2", currentStep >= 2 ? "d-block" : "d-none")}>
                      <FormLabel component="legend" className="form-section-title">Angaben zur Person</FormLabel>
                      <div className="">
                        <TextField
                          required
                          id="outlined-sur-name"
                          label="Vorname des Auftraggebers"
                          autoComplete="given-name"
                          margin="normal"
                          type="text"
                          name="firstname"
                          variant="outlined"
                          className="material-input col-12 col-md-6"
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                        <TextField
                          required
                          id="outlined-last-name"
                          label="Nachname des Auftraggebers"
                          autoComplete="family-name"
                          margin="normal"
                          type="text"
                          name="lastname"
                          variant="outlined"
                          className="material-input col-12 col-md-6"
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </div>
                      <TextField
                        required
                        id="outlined-required-street"
                        label="Straße, Nr."
                        autoComplete="street-address"
                        margin="normal"
                        type="text"
                        name="street"
                        variant="outlined"
                        className="material-input col-12 col-md-6"
                        onChange={(e) => setStreet(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-required-zip"
                        label="PLZ"
                        autoComplete="postal-code"
                        margin="normal"
                        type="text"
                        name="zip"
                        variant="outlined"
                        className="material-input col-12 col-md-2"
                        onChange={(e) => setZip(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-required-city"
                        label="Ort"
                        autoComplete="locality"
                        margin="normal"
                        type="text"
                        name="city"
                        variant="outlined"
                        className="material-input col-12 col-md-4"
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-email-input"
                        label="Email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        className="material-input col-12 col-md-6"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-required-hotline-prefix"
                        label="Vorwahl"
                        margin="normal"
                        type="text"
                        name="prefix"
                        variant="outlined"
                        className="material-input col-12 col-md-2"
                        onChange={(e) => setPrefix(e.target.value)}
                      />
                      <TextField
                        required
                        id="outlined-required-phone"
                        label="Telefon"
                        autoComplete="tel"
                        margin="normal"
                        type="text"
                        name="phone"
                        variant="outlined"
                        className="material-input col-12 col-md-4"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <TextField
                        id="outlined-multiline-static"
                        label="Bemerkungen"
                        multiline
                        type="text"
                        name="message"
                        margin="normal"
                        variant="outlined"
                        className="material-input col-12"
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                    <div className={cn("flex-grow-1 mb-2", currentStep >= 3 ? "d-block" : "d-none")}>
                      <FormControl component="fieldset" className="form-control col-12 col-lg-6 mb-2 mb-lg-0">
                        <FormLabel component="legend" className="form-section-title">Extras</FormLabel>
                        {packageType === 1 && (
                          <FormControlLabel
                            control={(
                              <Checkbox
                                onChange={(e) => setExtraAppleJuice(e.target.value)}
                                value="false"
                              />
                            )}
                            label="Apfelschorle Flatrate (4,50 €/ Kind)"
                          />
                        )}
                        {packageType !== 3 && (
                          <>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  onChange={(e) => setExtraInvitation(e.target.value)}
                                  value="false"
                                />
                              )}
                              label="Einladungskarten für die Geburtstagsgäste (2,50 €)"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  onChange={(e) => setExtraFruits(e.target.value)}
                                  value="false"
                                />
                              )}
                              label="Obstteller (1,50 €/ Kind)"
                            />
                          </>
                        )}
                        <FormControlLabel
                          control={(
                            <Checkbox
                              onChange={(e) => setExtraSweets(e.target.value)}
                              value="false"
                            />
                          )}
                          label="Süße Überraschungstüten (5 €/ Kind)"
                        />
                      </FormControl>
                      <FormControl component="fieldset" className="form-control col-12 col-md-6 col-lg-3">
                        <FormLabel component="legend">Thementischdecke (12 €)</FormLabel>
                        <RadioGroup
                          aria-label="Thementischdecke"
                          name="extra-blanket"
                          onChange={(e) => setExtraBlanket(e.target.value)}
                        >
                          <FormControlLabel value="10136" control={<Radio />} label="Dschungel-Party" />
                          <FormControlLabel value="10135" control={<Radio />} label="Piraten-Party" />
                          <FormControlLabel value="10134" control={<Radio />} label="Prinzessinnen-Party" />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset" className="col-12 col-md-6 col-lg-3 mt-1 mt-md-0">
                        <FormLabel component="legend">Geburtstagskuchen</FormLabel>
                        <RadioGroup
                          aria-label="Thementischdecke"
                          name="extra-blanket"
                          onChange={(e) => setExtraCake(e.target.value)}
                        >
                          <FormControlLabel value="chokolate-smarties" control={<Radio />} label="Schokoladenkuchen mit Smarties (24 €)" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className={cn("flex-grow-1 justify-content-between privacy-checks", currentStep >= 3 ? "d-block d-md-flex" : "d-none")}>
                      <FormControl component="fieldset" className="col-12 col-md-9 form-control">
                        <FormLabel component="legend" className="form-section-title">Datenschutz</FormLabel>
                        <div className="MuiFormControlLabel-root">
                          <Checkbox
                            required
                            id="privacy-checkbox"
                            onChange={(e) => setPrivacy(e.target.value)}
                            value="false"
                          />
                          <label htmlFor="privacy-checkbox">
                            <span className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label">
                              Hiermit erklären Sie sich mit unseren&nbsp;
                              <Link to="/datenschutz" className="underline">Datenschutzerklärungen</Link>
                              &nbsp;einverstanden. Selbstverständlich können Sie Ihre erteilte Einwilligung jederzeit widerrufen.
                            </span>
                          </label>
                        </div>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              onChange={(e) => setNewsletterSubscription(e.target.value)}
                              value="false"
                            />
                          )}
                          label="Hiermit erklären Sie sich zum Erhalt unseres Newsletters einverstanden. Selbstverständlich können Sie Ihre erteilte Einwilligung jederzeit widerrufen."
                        />
                      </FormControl>
                      {
                        (submitBookingSuccess && privacy) ? (
                          <Button
                            className="btn-contact-submit text-white bg-green text-md px-1 mt-1 ml-auto"
                            realButton
                            text="✔"
                            disabled
                          />
                        ) : (
                          <Button
                            className="btn-contact-submit text-white text-md px-1 mt-1 ml-auto d-block"
                            realButton
                            text="Senden"
                          />
                        )
                      }
                    </div>
                    <div className="steps-wrapper d-block flex-grow-1">
                      <div className="d-flex">
                        <Button
                          className={cn("btn-contact-submit text-white bg-orange text-md px-1 ml-auto", currentStep === 3 ? "d-none" : "d-block")}
                          text="weiter >"
                          onClick={((currentStep === 1) && (date && time && birthdayDate && firstNameBirthdayChild && adults && children) || (firstname && lastname && street && zip && city && prefix && phone && email && updateSteps) || (privacy && updateSteps)) ? updateSteps : null}
                        />
                      </div>
                      <div className="steps-markers-wrapper mx-auto mt-2">
                        <div className={cn("step step-1", currentStep >= 1 && "bg-gray")} />
                        <div className={cn("step step-2", currentStep >= 2 && "bg-gray")} />
                        <div className={cn("step step-3", currentStep >= 3 && "bg-gray")} />
                      </div>
                    </div>
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                  </form>
                )
              }
            </div>
          </div>
          <div className="row message-row justify-content-center" ref={bookingMessageRef}>
            {
              (submitBookingSuccess || submitBookingError) && (
                <div className="col-12 col-lg-10 rounded-shadow text-align-center success-container p-4">
                  {
                    submitBookingSuccess && (
                      <>
                        <SvgIconSmiley className="mb-2" />
                        <p>
                          Vielen Dank für Ihre Anfrage!
                          <br />
                          Vielen Dank für Ihre Anfrage. Sie erhalten in kürze eine Bestätigung ( freier Platz vorausgesetzt ) mit den wichtigsten Fragen & Antworten.
                        </p>
                      </>
                    )
                  }
                  {
                    submitBookingError && (
                      <p>
                        Leider gab es ein technisches Problem bei Ihrer Anfrage.
                        <br />
                        Bitte versuchen es erneut oder kontaktieren Sie uns direkt via
                        <a href="mailto:info@juxundtollerei.com" target="_blank" rel="noopener noreferrer" className="text-m bold">&nbsp;info@juxundtollerei.com</a>
                      </p>
                    )
                  }
                </div>
              )
            }
          </div>
        </>
      )}
    </div>
  );
}

export default BookingForm
